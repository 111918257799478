<template lang="pug">
div
  .ViewerDrawer.card(ref='slideMenu')
    b-tooltip(label='Draw', position='is-left', v-if='!showDrawerMenu')
      BButton.ViewerDrawer-showbtn(@click='setDrawMode(); animateMenu()', icon-left='pen')
    BTooltip(label='Close', position='is-left', v-else)
      BButton.ViewerDrawer-showbtn(@click='returnToViewer()', icon-left='times')

    .ViewerDrawer-toolbar(v-show='showDrawerMenu')
      BTooltip(label='Save', position='is-left') 
        a.download-draw.button(:href='downloadImgUrl', download, @click='saveImgToCloud()')
          BIcon(icon='check', size='is-small')

      BTooltip(label='Color', position='is-left') 
        input.ViewerDrawer-color(
          type='color',
          v-model='activeColor',
          @change='setCanvasOptions({ strokeStyle: activeColor, fillStyle: activeColor })'
        )

      BTooltip(label='Draw', position='is-left') 
        BButton(
          @click='setMode("draw", "none")',
          icon-left='pen',
          :type='mode === "draw" ? "is-primary" : ""'
        )

      BTooltip(label='Stroke size', position='is-left')
        BButton(@click='showSlider = !showSlider', icon-left='circle')

      BSlider(
        v-model='lineWidth',
        @change='setCanvasOptions({ lineWidth }); setPenSize(lineWidth)',
        v-if='showSlider'
      )

      BTooltip(label='Draw lines', position='is-left')
        BButton(
          @click='setMode("line", "pointer")',
          icon-left='grip-lines-vertical',
          :type='mode === "line" ? "is-primary" : ""'
        )
      BTooltip(label='Text', position='is-left')
        BButton(
          @click='setMode("write", "text")',
          icon-left='paragraph',
          :type='mode === "write" ? "is-primary" : ""'
        )
        textarea(
          cols='20',
          rows='3',
          v-model='text',
          @keyup.enter='setText',
          v-show='writingModal',
          :style='textAreaStyles'
        )
  #pen.pen(ref='pen', v-show='showDrawerMenu && mode === "draw"', :style='{ opacity: showPen }')
</template>

<script>
import helpers from '@/components/meeting/ViewerDrawer/helpers'
import mixins from '@/components/meeting/ViewerDrawer/mixins/index'
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ViewerDrawer',
  mixins: mixins,
  data() {
    return {
      mode: 'draw',
      activeColor: '#000000',
      x: 0,
      y: 0,
      showSlider: false,
      toolbar: null,
      showPen: 1
    }
  },
  methods: {
    setMode(mode, cursor) {
      this.mode = mode
      this.setCursor(this.canvasElement, cursor)
    },
    setCursorPosition(clientX, clientY) {
      this.x = clientX - this.canvasCords.x
      this.y = clientY - this.canvasCords.y
      if (this.mode === 'draw') {
        this.$refs.pen.style.top = this.y + 'px'
        this.$refs.pen.style.left = this.x + 'px'
      }
    },
    animateMenu() {
      // this.$refs.slideMenu.classList.add('scale-up-ver-top')
    },
    ...helpers
  },
  computed: {
    ...mapGetters(['userId']),
    ...mapState(['meeting'])
  }
}
</script>

<style lang="scss">
.ViewerDrawer {
  position: relative;
  padding: 15px;
  max-height: 500px;
  &:hover {
    cursor: default;
  }
  &-toolbar {
    display: flex;
    flex-direction: column;
    textarea {
      font-size: 30px;
      position: fixed !important;
      visibility: none;
      background-color: rgba(255, 255, 255, 0.397);
      border: 1px solid #dbdbdb;
      outline: none;
      resize: none;
      &:focus {
        border: none;
        border-image: none;
      }
    }
    button {
      width: fit-content;
    }
  }

  .ViewerDrawer-color {
    @include size(35px);
    border: 1px solid #dbdbdb;
    cursor: pointer;
  }
}

.scale-up-ver-top {
  animation: scale-up-ver-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes scale-up-ver-top {
  0% {
    transform: scaleY(0.1);
    transform-origin: 100% 0%;
  }
  100% {
    transform: scaleY(1);

    transform-origin: 100% 0%;
  }
}

.pen {
  @include posAbsolute();
  @include size(8px);
  opacity: 1;
  position: fixed;
  border-radius: 100%;
  background-color: black;
  z-index: 90;
  user-select: none;
  pointer-events: none;
}
.draw-active {
  position: relative;
  z-index: 100;
}
.canvas-drawer-active {
  background-color: transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
  cursor: none;
}
</style>
