var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BLoading",
    {
      staticClass: "LoadingMeet",
      attrs: {
        active: !_vm.meet || _vm.fetching || _vm.loading,
        "can-cancel": false
      }
    },
    [
      _c("div", { staticClass: "progress-container" }, [
        _vm.hasErrors
          ? _c(
              "div",
              { staticClass: "has-text-weight-bold has-text-centered" },
              [
                _c("img", {
                  staticClass: "broken-house",
                  attrs: {
                    src: require("@/assets/images/broken-house-svgrepo-com.svg")
                  }
                }),
                _vm._l(_vm.errors, function(error, index) {
                  return _c(
                    "p",
                    { key: index, staticClass: "title is-4 has-text-danger" },
                    [_vm._v(" " + _vm._s(error.message) + " ")]
                  )
                })
              ],
              2
            )
          : _c(
              "div",
              [
                _c(
                  "p",
                  { staticClass: "has-text-primary has-text-weight-bold" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          !_vm.progress
                            ? "Getting meeting details..."
                            : "Loading meeting model...."
                        ) +
                        " "
                    )
                  ]
                ),
                _c("BProgress", {
                  attrs: {
                    value: _vm.progress,
                    "show-value,": "",
                    format: "percent",
                    type: "is-primary"
                  }
                })
              ],
              1
            )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }