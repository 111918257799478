var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chat__container" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "chat-scroll",
            rawName: "v-chat-scroll",
            value: { smooth: true },
            expression: "{ smooth: true }"
          }
        ],
        staticClass: "chat__scroll-sentences"
      },
      _vm._l(_vm.sentences, function(item, index) {
        return _c("Sentence", { key: index, attrs: { content: item } })
      }),
      1
    ),
    _c("div", { staticClass: "chat__input-container" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.message,
            expression: "message"
          }
        ],
        staticClass: "chat__input-message",
        attrs: {
          id: "chat-input",
          type: "text",
          placeholder: "Type a message",
          autocomplete: "off"
        },
        domProps: { value: _vm.message },
        on: {
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.sendMessage($event)
          },
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.message = $event.target.value
          }
        }
      }),
      _c(
        "span",
        {
          staticClass: "chat__emoji-icon",
          style: _vm.emojiColourWhenOpened,
          on: {
            click: function($event) {
              _vm.showEmojis = !_vm.showEmojis
            }
          }
        },
        [_vm._v(" 😀 ")]
      ),
      _vm.showEmojis
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "on-clickaway",
                  rawName: "v-on-clickaway",
                  value: _vm.hideModal,
                  expression: "hideModal"
                }
              ],
              staticClass: "chat__emoji-list"
            },
            [
              _c("VEmojiPicker", {
                attrs: { emojiSize: 28 },
                on: { select: _vm.selectEmoji }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "chat__input-send-button",
          on: { click: _vm.sendMessage }
        },
        [_vm._v(">")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }