<template lang="pug">
.card
  .card-header
    .card-header-title
      p Invite guests to this meet!

  .card-content
    p.subtitle.is-7 Only the following users will have access to this meeting.
    BTaginput.Modal-spacing(
      v-model='meet.guests',
      placeholder='Invite people to this meet',
      icon='user',
      maxtags='3',
      @input='$emit("guest-modified", meet.guests)'
    )
    BTooltip(label='Public meets will be allowed soon!', position='is-bottom')
      BCheckbox(size='is-small', disabled) Make this meeting public?
    hr
    p.subtitle.is-7 Share the code or the direct link to this meet with your guests!

    BField.Modal-spacing
      p.control
        span.button.is-static Meet code
      BInput(:placeholder='id', expanded)
      button.button.is-primary.clipboard-btn(:data-clipboard-text='id') Copy

    BField
      p.control
        span.button.is-static Meet url
      BInput(:placeholder='meetUrl', expanded)
      button.button.is-primary.clipboard-btn(:data-clipboard-text='meetUrl') Copy
</template>

<script>
export default {
  data() {
    return {
      meetUrl: window.location.href
    }
  },
  name: 'InviteModal',
  props: ['meet', 'id'],
  methods: {
    parent() {
      console.log(this.$parent)
    }
  }
}
</script>

<style lang="scss" scoped>
.Modal-spacing {
  margin-bottom: 10px !important;
}
</style>
