var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-field",
    [
      _vm.userIsOwner
        ? _c(
            "b-dropdown",
            {
              attrs: { "close-on-click": false },
              scopedSlots: _vm._u(
                [
                  {
                    key: "trigger",
                    fn: function(ref) {
                      var active = ref.active
                      return _c(
                        "button",
                        {
                          staticClass: "button",
                          class: active ? "is-info" : ""
                        },
                        [
                          _c(
                            "span",
                            {
                              class: active ? "has-text-white" : "has-text-info"
                            },
                            [_vm._v("Model")]
                          ),
                          _c("b-icon", {
                            attrs: {
                              icon: active ? "caret-up" : "caret-down",
                              type: active ? "is-white" : "is-info"
                            }
                          })
                        ],
                        1
                      )
                    }
                  }
                ],
                null,
                false,
                1932084649
              )
            },
            [
              _c(
                "b-dropdown-item",
                {
                  staticClass: "is-marginless is-paddingless",
                  attrs: { focusable: false, custom: "" }
                },
                [_c("AutodeskHubs", { on: { selected: _vm.onModelSelected } })],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("p", { staticClass: "control" }, [
        _c("span", { staticClass: "button is-static" }, [
          _vm._v("Currently presenting")
        ])
      ]),
      _vm.meet.owner === _vm.$auth.currentUser.uid
        ? _c(
            "b-dropdown",
            { on: { change: _vm.onPresenterChange } },
            [
              _c(
                "b-button",
                {
                  attrs: { slot: "trigger", "icon-right": "chevron-down" },
                  slot: "trigger"
                },
                [_vm._v(_vm._s(_vm.meet.presenter))]
              ),
              _c(
                "b-dropdown-item",
                { attrs: { value: _vm.$auth.currentUser.email } },
                [_vm._v(_vm._s(_vm.$auth.currentUser.email))]
              ),
              _vm._l(_vm.meet.guests, function(guest, index) {
                return _c(
                  "b-dropdown-item",
                  { key: index, attrs: { value: guest } },
                  [_vm._v(_vm._s(guest))]
                )
              })
            ],
            2
          )
        : _c("b-input", { attrs: { value: _vm.meet.presenter, disabled: "" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }