<template lang="pug">
#draggable-container(
  ref='draggableContainer',
  @mouseenter='$parent.toggleUserPointer("enter")',
  @mouseleave='$parent.toggleUserPointer("leave")'
)
  #draggable-header(@mousedown='dragMouseDown')
    slot(name='header')
  slot
  slot(name='footer')
</template>

<script>
export default {
  name: 'DraggableDiv',
  data: function () {
    return {
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      },
      toggleMinimize: false
    }
  },
  methods: {
    dragMouseDown: function (event) {
      event.preventDefault()
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },

    elementDrag: function (event) {
      event.preventDefault()
      this.positions.movementX = this.positions.clientX - event.clientX
      this.positions.movementY = this.positions.clientY - event.clientY
      const { movementX, movementY } = this.positions

      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      const { offsetTop, offsetLeft } = this.$refs.draggableContainer

      this.filterPosition({
        offsetTop: offsetTop - movementY,
        offsetLeft: offsetLeft - movementX
      })
      // set the element's new position:
    },
    filterPosition({ offsetTop, offsetLeft }) {
      const { clientWidth, clientHeight } = this.$refs.draggableContainer
      const maxRightPos = window.innerWidth - clientWidth
      const maxBottomPos = window.innerHeight - clientHeight

      if (offsetLeft < 0) offsetLeft = 0
      if (offsetTop < 0) offsetTop = 0
      if (offsetLeft > maxRightPos) offsetLeft = maxRightPos
      if (offsetTop > maxBottomPos) offsetTop = maxBottomPos

      this.$refs.draggableContainer.style.top = offsetTop + 'px'

      this.$refs.draggableContainer.style.left = offsetLeft + 'px'
    },
    closeDragElement() {
      document.onmouseup = null
      document.onmousemove = null
    }
  }
}
</script>

<style lang="scss" scoped>
#draggable-container {
  position: absolute;
  z-index: 20;
  height: fit-content;
  padding-bottom: 15px;
  cursor: default !important;
}
#draggable-header {
  z-index: 10;
}
</style>
