var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "draggableContainer",
      attrs: { id: "draggable-container" },
      on: {
        mouseenter: function($event) {
          return _vm.$parent.toggleUserPointer("enter")
        },
        mouseleave: function($event) {
          return _vm.$parent.toggleUserPointer("leave")
        }
      }
    },
    [
      _c(
        "div",
        {
          attrs: { id: "draggable-header" },
          on: { mousedown: _vm.dragMouseDown }
        },
        [_vm._t("header")],
        2
      ),
      _vm._t("default"),
      _vm._t("footer")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }