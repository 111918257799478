var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-field",
    { staticClass: "bottom-right", attrs: { horizontal: "" } },
    [
      _c("BButton", { on: { click: _vm.snapShot } }, [_vm._v("Screenshot")]),
      _c(
        "b-dropdown",
        {
          class: {
            "is-hidden": !_vm.userIsOwner || !_vm.forgeViewer,
            "is-disabled": _vm.meet.containerId === undefined
          },
          attrs: {
            position: "is-top-left",
            "max-height": 400,
            "mobile-modal": false,
            scrollable: ""
          },
          scopedSlots: _vm._u([
            {
              key: "trigger",
              fn: function(ref) {
                var active = ref.active
                return _c(
                  "button",
                  { staticClass: "button", class: active ? "is-info" : "" },
                  [
                    _c(
                      "span",
                      { class: active ? "has-text-white" : "has-text-info" },
                      [_vm._v("Viewables")]
                    ),
                    _c("b-icon", {
                      attrs: {
                        icon: active ? "caret-down" : "caret-up",
                        type: active ? "is-white" : "is-info"
                      }
                    })
                  ],
                  1
                )
              }
            }
          ])
        },
        _vm._l(_vm.modelViewables, function(item) {
          return _c(
            "b-dropdown-item",
            {
              key: item.data.guid,
              attrs: { "aria-role": "listitem", focusable: false },
              on: {
                click: function($event) {
                  return _vm.onViewableSelected(item)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "media" },
                [
                  _c("b-icon", {
                    staticClass: "media-left",
                    attrs: {
                      icon: item.data.role === "3d" ? "cube" : "ruler-combined",
                      size: "is-small"
                    }
                  }),
                  _c("div", { staticClass: "media-content" }, [
                    _c("span", [_vm._v(_vm._s(item.data.name))])
                  ])
                ],
                1
              )
            ]
          )
        }),
        1
      ),
      _vm.meet.owner === _vm.$auth.currentUser.uid
        ? _c(
            "BButton",
            {
              on: {
                click: function($event) {
                  _vm.isComponentModalActive = true
                }
              }
            },
            [_vm._v("Guests")]
          )
        : _vm._e(),
      _c(
        "BModal",
        {
          staticClass: "guest-modal",
          attrs: { "has-modal-card": "", active: _vm.isComponentModalActive },
          on: {
            "update:active": function($event) {
              _vm.isComponentModalActive = $event
            }
          }
        },
        [
          _c(
            "InviteModal",
            _vm._b(
              { on: { "guest-modified": _vm.onGuestModified } },
              "InviteModal",
              _vm.$props,
              false
            )
          )
        ],
        1
      ),
      _c(
        "b-dropdown",
        {
          class: {
            "is-hidden": !_vm.userIsOwner || !_vm.forgeViewer,
            "is-disabled": _vm.meet.containerId === undefined
          },
          attrs: {
            position: "is-top-left",
            "close-on-click": false,
            "max-height": 400,
            "can-close": false,
            "mobile-modal": false
          },
          scopedSlots: _vm._u([
            {
              key: "trigger",
              fn: function(ref) {
                var active = ref.active
                return _c(
                  "button",
                  {
                    staticClass: "button",
                    class: active ? "is-info" : "",
                    attrs: { id: "issuesbtn" }
                  },
                  [
                    _c(
                      "span",
                      { class: active ? "has-text-white" : "has-text-info" },
                      [_vm._v("Issues")]
                    ),
                    _c("b-icon", {
                      attrs: {
                        icon: active ? "caret-down" : "caret-up",
                        type: active ? "is-white" : "is-info"
                      }
                    })
                  ],
                  1
                )
              }
            }
          ])
        },
        [
          _vm.meet.containerId &&
          _vm.forgeViewer !== null &&
          _vm.meet.itemId &&
          _vm.progress > 0
            ? _c(
                "b-dropdown-item",
                { attrs: { focusable: false, custom: "" } },
                [
                  _c("bim360-issue-viewer", {
                    attrs: {
                      "container-id": _vm.meet.containerId,
                      viewer: _vm.forgeViewer,
                      "model-urn": _vm.meet.itemId,
                      "current-state": _vm.currentState,
                      "meet-id": _vm.id,
                      "screen-shot": _vm.screenShot
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("canvas", {
        staticStyle: { position: "absolute" },
        attrs: { id: "snapshot" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }