var forgeViewerStateFilter = {
    cutplanes: true,
    objectSet: true,
    renderOptions: false,
    seedURN: true,
    viewport: true
};
var position = {
    x: 0,
    y: 0,
    z: 0.5
};
export { forgeViewerStateFilter, position };
