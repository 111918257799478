import { __decorate, __extends } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
var Sentence = (function (_super) {
    __extends(Sentence, _super);
    function Sentence() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Sentence.prototype.rowAccordingGuest = function () {
        return this.isMessageFromGuest ? 'chat__row-guest' : 'chat__row-user';
    };
    Sentence.prototype.containerAccordingGuest = function () {
        return this.isMessageFromGuest ? 'chat__container-guest' : 'chat__container-user';
    };
    Sentence.prototype.colorAccordingGuest = function () {
        return this.isMessageFromGuest ? 'chat__text-color-guest' : 'chat__text-color-user';
    };
    Sentence.prototype.getTime = function () {
        return Date().slice(16, 21);
    };
    Sentence.prototype.timeColorAccordingGuest = function () {
        return this.isMessageFromGuest
            ? { color: 'rgba(141, 141, 141, 0.726);' }
            : { color: 'rgba(255, 255, 255, 0.726);' };
    };
    Object.defineProperty(Sentence.prototype, "isMessageFromGuest", {
        get: function () {
            return this.$auth.currentUser.email === this.content.name;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop()
    ], Sentence.prototype, "content", void 0);
    Sentence = __decorate([
        Component
    ], Sentence);
    return Sentence;
}(Vue));
export default Sentence;
