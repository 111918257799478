export default {
    methods: {
        setupVideoCall: function (room) {
            var _this = this;
            this.room = room;
            this.$emit('connected');
            console.log("Connected to the Room as LocalParticipant \"" + room.localParticipant.identity + "\"");
            this.userPic(room.localParticipant.identity).then(function (url) { return (_this.participantPics[room.localParticipant.identity] = url); });
            room.participants.forEach(function (participant) {
                console.log("Participant \"" + participant.identity + "\" is connected to the Room");
                _this.userPic(participant.identity).then(function (url) {
                    _this.participantPics[participant.identity] = url;
                    _this.participants = Array.from(room.participants.values());
                    _this.$store.commit('setTwilioParticipants', _this.participants);
                });
            });
            room.once('participantConnected', function (participant) {
                console.log("Participant \"" + participant + "\" has connected to the Room");
            });
            room.once('participantDisconnected', function (participant) {
                console.log("Participant \"" + participant.identity + "\" has disconnected from the Room");
            });
            room.on('participantConnected', function (participant) {
                console.log("A remote Participant connected: " + participant);
                _this.userPic(participant.identity).then(function (url) {
                    _this.participantPics[participant.identity] = url;
                    _this.participants = Array.from(room.participants.values());
                    _this.$store.commit('setTwilioParticipants', _this.participants);
                });
            });
            room.on('participantDisconnected', function (participant) {
                console.log("Participant disconnected: " + participant.identity);
                _this.participants = Array.from(room.participants.values());
                _this.$store.commit('setTwilioParticipants', _this.participants);
            });
        }
    },
    destroyed: function () {
        var _a, _b;
        if (this.room) {
            this.room.localParticipant.videoTracks.forEach(function (publication) {
                console.log('cancelling video track');
                publication.track.stop();
                publication.unpublish();
            });
            this.room.localParticipant.audioTracks.forEach(function (publication) {
                console.log('cancelling audio track');
                publication.track.stop();
                publication.unpublish();
            });
            (_a = this.room) === null || _a === void 0 ? void 0 : _a.disconnect();
            (_b = this.room) === null || _b === void 0 ? void 0 : _b.removeAllListeners();
        }
    }
};
