var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DraggableDiv",
    { ref: "DraggableDiv", staticClass: "card" },
    [
      _c("template", { slot: "header" }, [
        _c(
          "div",
          { staticClass: "card-header" },
          [
            _c("LogoBimMeet", { staticClass: "LogoBimMeet" }),
            _c("BIcon", {
              staticClass: "minimize-icon",
              attrs: { icon: "window-minimize" },
              nativeOn: {
                click: function($event) {
                  return _vm.$store.commit("setModalState", true)
                }
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "twilio-room", style: "--user-color: " + _vm.colors[0] },
        [
          _c(
            "div",
            { staticClass: "connected-participants" },
            [
              _c("Avatar", {
                staticClass: "user-thumb",
                attrs: {
                  src: _vm.$store.state.user.photoURL || "",
                  size: 32,
                  username: _vm.$store.getters.userNick,
                  "background-color": _vm.colors[0]
                }
              }),
              _vm._l(_vm.participants, function(participant, index) {
                return _c("Avatar", {
                  key: index,
                  staticClass: "user-thumb",
                  attrs: {
                    username: participant.identity,
                    src: _vm.participantPics[participant.identity] || "",
                    size: 32,
                    "background-color": _vm.colors[index + 1],
                    color: "#fff"
                  }
                })
              }),
              _c(
                "p",
                {
                  staticClass: "icon has-text-weight-light has-text-grey-light"
                },
                [_vm._v("|")]
              ),
              _c("b-button", {
                staticClass: "video-btn has-text-grey-light",
                style: !_vm.hiddenMedia && _vm.openedFeatureStyle,
                attrs: { "icon-left": "video", type: "is-text" },
                on: {
                  click: function($event) {
                    return _vm.toggleMedia()
                  }
                }
              }),
              _c("b-button", {
                staticClass: "chat-btn has-text-grey-light",
                style: _vm.showChat && _vm.openedFeatureStyle,
                attrs: { "icon-left": "comment-alt", type: "is-text" },
                on: {
                  click: function($event) {
                    return _vm.toggleChat()
                  }
                }
              })
            ],
            2
          )
        ]
      ),
      _vm.room
        ? _c(
            "div",
            {
              staticClass: "card-content media-container",
              class: { hidden: _vm.hiddenMedia }
            },
            [
              _c("TwilioMedia", {
                attrs: {
                  user: _vm.room.localParticipant,
                  width: _vm.videoWidth + "px",
                  height: _vm.videoHeight + "px"
                }
              }),
              _vm._l(_vm.participants, function(participant, index) {
                return _c("TwilioMedia", {
                  key: index,
                  attrs: {
                    user: participant,
                    width: _vm.videoWidth + "px",
                    height: _vm.videoHeight + "px"
                  }
                })
              })
            ],
            2
          )
        : _vm._e(),
      _c("ChatWidget", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showChat,
            expression: "showChat"
          }
        ],
        attrs: { clicked: _vm.showChat }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }