import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
function defaultPoint() {
    return { x: 0, y: 0 };
}
var UserPointer = (function (_super) {
    __extends(UserPointer, _super);
    function UserPointer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(UserPointer.prototype, "id", {
        get: function () {
            return 'pointer-' + this.userId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserPointer.prototype, "style", {
        get: function () {
            return {
                '--color': this.color,
                top: this.position.y + "px",
                left: this.position.x + "px",
                color: this.color
            };
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop()
    ], UserPointer.prototype, "userId", void 0);
    __decorate([
        Prop({ default: defaultPoint })
    ], UserPointer.prototype, "position", void 0);
    __decorate([
        Prop({ default: '#e32323' })
    ], UserPointer.prototype, "color", void 0);
    UserPointer = __decorate([
        Component
    ], UserPointer);
    return UserPointer;
}(Vue));
export default UserPointer;
