import { __assign, __decorate, __extends } from "tslib";
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import Clipboard from 'clipboard';
import TwilioRoom from '@/components/twilio/TwilioRoom.vue';
import MediaSelect from '@/components/twilio/MediaSelect.vue';
import ViewerDrawer from '@/components/meeting/ViewerDrawer.vue';
import { forgeViewerStateFilter, position } from '@/views/Meeting/forgeViewerStateFilter';
import api from './Meeting/api';
import LoginRequired from '@/components/meeting/LoginRequired.vue';
import LoadingMeet from '@/components/meeting/LoadingMeet.vue';
import BottomRightMenu from '@/components/meeting/BottomRightMenu.vue';
import TopLeftMenu from '@/components/meeting/TopLeftMenu.vue';
import LogoBimMeet from '@/components/common/LogoBimMeet.vue';
import UserPointer from '@/components/meeting/UserPointer.vue';
import { colors } from '@/components/twilio/TwilioRoom/colors';
import Markup from '@/components/autodeskForge/Markup/Markup.vue';
var Meeting = (function (_super) {
    __extends(Meeting, _super);
    function Meeting() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.hasUserApproval = false;
        _this.fetching = true;
        _this.roomActive = false;
        _this.loading = true;
        _this.progress = null;
        _this.meet = null;
        _this.errors = [];
        _this.disconnectSnapshot = null;
        _this.receivedPositions = null;
        _this.currentState = null;
        _this.previousState = null;
        _this.colors = colors;
        _this.forgeViewerStateFilter = forgeViewerStateFilter;
        _this.interval = null;
        _this.position = position;
        _this.twilioOptions = null;
        _this.modelViewables = [];
        _this.updateInterval = 1000 / 30;
        _this.showDrawer = false;
        _this.hasBeenLoaded = false;
        return _this;
    }
    Object.defineProperty(Meeting.prototype, "hasErrors", {
        get: function () {
            return this.errors.length != 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Meeting.prototype, "userIsPresenter", {
        get: function () {
            var _a, _b;
            return (((_a = this.meet) === null || _a === void 0 ? void 0 : _a.presenter) === this.$auth.currentUser.email ||
                ((_b = this.meet) === null || _b === void 0 ? void 0 : _b.presenter) === this.$auth.currentUser.uid);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Meeting.prototype, "userIsOwner", {
        get: function () {
            var _a;
            return ((_a = this.meet) === null || _a === void 0 ? void 0 : _a.owner) === this.$auth.currentUser.uid;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Meeting.prototype, "isModelLoaded", {
        get: function () {
            return this.$store.getters.isModelLoaded;
        },
        enumerable: false,
        configurable: true
    });
    Meeting.prototype.onViewerStateChanged = function (state) {
        this.currentState = state;
    };
    Meeting.prototype.onUserJoin = function (audio, video) {
        this.twilioOptions = {
            audio: audio,
            video: video,
            audioEnabled: audio !== null,
            videoEnabled: video !== null
        };
        this.hasUserApproval = true;
    };
    Meeting.prototype.onViewableSelected = function (viewable) {
        console.log('viewable selected', viewable.data.name);
        this.updateMeet({ viewableId: viewable.data.guid });
    };
    Meeting.prototype.onViewablesReady = function (viewables) {
        console.log('viewables recieved', viewables);
        this.modelViewables = viewables;
    };
    Meeting.prototype.onRoomConnected = function () {
        this.roomActive = true;
    };
    Meeting.prototype.onRoomError = function (error) {
        this.errors.push(error);
        this.roomActive = false;
    };
    Meeting.prototype.mounted = function () {
        this.loading = true;
        this.fetching = true;
        this.disconnectSnapshot = this.$firestore
            .doc("meets/" + this.id)
            .onSnapshot({ includeMetadataChanges: true }, this.onSnapshotReceived, this.onSnapshotError);
        new Clipboard('.clipboard-btn');
    };
    Meeting.prototype.destroyed = function () {
        var _a, _b;
        clearInterval(this.interval);
        (_a = this.positionSubscription) === null || _a === void 0 ? void 0 : _a.stop();
        (_b = this.stateSubscription) === null || _b === void 0 ? void 0 : _b.stop();
        this.disconnectSnapshot();
    };
    Meeting.prototype.updateMeet = function (data) {
        return this.$firestore.doc("meets/" + this.id).update(data);
    };
    Meeting.prototype.onMouseUpdate = function (e) {
        if (this.forgeViewer !== undefined && this.forgeViewer !== null) {
            var viewer = this.forgeViewer.viewer;
            if (!viewer)
                return;
            var width = window.innerWidth, height = window.innerHeight;
            var worldPos = viewer.navigation.getWorldPoint(e.clientX / width, e.clientY / height);
            var pos2 = worldPos.clone();
            pos2.x = (e.clientX / width) * 2 - 1;
            pos2.y = -(e.clientY / height) * 2 + 1;
            pos2.z = 0.5;
            var v = pos2.unproject(viewer.getCamera());
            var len = v.length();
            if (len > 0) {
                this.position.x = v.x;
                this.position.y = v.y;
                this.position.z = v.z;
            }
        }
    };
    Meeting.prototype.onSnapshotReceived = function (snapshot) {
        if (snapshot.exists) {
            this.meet = snapshot.data();
            this.$log('success', 'Successfully loaded meeting', __assign({}, this.meet));
            this.$store.dispatch('updateMeeting', this.meet);
            this.meet.id = this.id;
        }
        this.fetching = false;
        if (this.forgeViewer && this.meet.modelURN)
            this.setupStateSubscription();
        this.loading = false;
    };
    Meeting.prototype.onSnapshotError = function (error) {
        console.error(error);
        this.errors.push(new Error('The meeting you are trying to access is protected or does not exist'));
    };
    Meeting.prototype.getForgeToken = function (callback) {
        var getTwoLegged = this.$functions.httpsCallable('forgeTwoLeggedToken');
        getTwoLegged()
            .then(function (value) {
            callback(value.data.access_token, value.data.expires_in);
        })
            .catch(function (err) { return console.error(err); });
    };
    Meeting.prototype.loadComplete = function () {
        this.hasBeenLoaded = true;
        this.loading = false;
        this.errors = [];
        this.setupUpdateInterval();
        this.setupMousePositionSubscription();
        this.setupStateSubscription();
    };
    Meeting.prototype.loadProgress = function (progress) {
        this.progress = progress.percent;
    };
    Meeting.prototype.setupUpdateInterval = function () {
        var _this = this;
        if (this.$auth.currentUser)
            this.interval = setInterval(function () {
                _this.sendPosition();
                if (_this.userIsPresenter)
                    _this.sendViewerState();
            }, this.updateInterval);
    };
    Meeting.prototype.sendPosition = function () {
        api.sendPosition(this.position, this.$auth.currentUser.email, this.id);
    };
    Meeting.prototype.setupMousePositionSubscription = function () {
        var _a;
        console.log('setting up mouse subscription');
        (_a = this.positionSubscription) === null || _a === void 0 ? void 0 : _a.stop();
        this.positionSubscription = this.$apollo.addSmartSubscription('positionsUpdated', {
            query: require('../graphql/PositionsUpdated.gql'),
            variables: {
                meetId: this.id
            },
            result: function (_a) {
                var _this = this;
                var data = _a.data;
                var projectedPositions = {};
                Object.keys(data.positionsUpdated.userPointers).forEach(function (key) {
                    var _a = data.positionsUpdated.userPointers[key], x = _a.x, y = _a.y, z = _a.z;
                    var width = window.innerWidth, height = window.innerHeight;
                    var worldPos = _this.forgeViewer.viewer.navigation.getWorldPoint(0, 0);
                    worldPos.x = x;
                    worldPos.y = y;
                    worldPos.z = z;
                    var screenPos = worldPos.project(_this.forgeViewer.viewer.getCamera());
                    screenPos.x = ((screenPos.x + 1) / 2) * width;
                    screenPos.y = ((-screenPos.y + 1) / 2) * height;
                    projectedPositions[key] = { x: screenPos.x, y: screenPos.y, z: 0 };
                });
                this.receivedPositions = projectedPositions;
            }
        });
        this.positionSubscription.start();
    };
    Meeting.prototype.setupStateSubscription = function () {
        var _a;
        console.log('setting up state subscription');
        (_a = this.stateSubscription) === null || _a === void 0 ? void 0 : _a.stop();
        if (!this.userIsPresenter) {
            this.stateSubscription = this.$apollo.addSmartSubscription('stateUpdated', {
                query: require('../graphql/StateUpdated.gql'),
                variables: { meetId: this.id },
                result: function (_a) {
                    var data = _a.data;
                    console.log('recieved viewer state');
                    this.forgeViewer.setState(data.stateUpdated);
                }
            });
            this.stateSubscription.start();
        }
    };
    Object.defineProperty(Meeting.prototype, "participants", {
        get: function () {
            var _a;
            return (_a = this.$store.state.twilio) === null || _a === void 0 ? void 0 : _a.participants;
        },
        enumerable: false,
        configurable: true
    });
    Meeting.prototype.sendViewerState = function () {
        if (this.currentState !== this.previousState) {
            console.log('sending viewer state');
            api.sendViewerState(this.currentState, this.id);
            this.previousState = this.currentState;
        }
    };
    __decorate([
        Prop()
    ], Meeting.prototype, "id", void 0);
    __decorate([
        Ref()
    ], Meeting.prototype, "forgeViewer", void 0);
    Meeting = __decorate([
        Component({
            components: {
                MediaSelect: MediaSelect,
                ForgeViewer: function () {
                    return import('@/components/autodeskForge/ForgeViewer.vue');
                },
                TwilioRoom: TwilioRoom,
                ViewerDrawer: ViewerDrawer,
                LoginRequired: LoginRequired,
                LoadingMeet: LoadingMeet,
                BottomRightMenu: BottomRightMenu,
                TopLeftMenu: TopLeftMenu,
                LogoBimMeet: LogoBimMeet,
                UserPointer: UserPointer,
                Markup: Markup
            }
        })
    ], Meeting);
    return Meeting;
}(Vue));
export default Meeting;
