import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
var VolumeIndicator = (function (_super) {
    __extends(VolumeIndicator, _super);
    function VolumeIndicator() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(VolumeIndicator.prototype, "radius", {
        get: function () {
            var range = this.max - this.min;
            var unit = this.volume / this.maxVolume;
            return this.min + range * unit;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop({ default: 5 })
    ], VolumeIndicator.prototype, "volume", void 0);
    __decorate([
        Prop({ default: 10 })
    ], VolumeIndicator.prototype, "maxVolume", void 0);
    __decorate([
        Prop({ default: 10 })
    ], VolumeIndicator.prototype, "max", void 0);
    __decorate([
        Prop({ default: 5 })
    ], VolumeIndicator.prototype, "min", void 0);
    VolumeIndicator = __decorate([
        Component
    ], VolumeIndicator);
    return VolumeIndicator;
}(Vue));
export default VolumeIndicator;
