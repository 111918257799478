<template lang="pug">
b-field
  b-dropdown(v-if='userIsOwner', :close-on-click='false')
    button.button(slot='trigger', slot-scope='{ active }', :class='active ? "is-info" : ""')
      span(:class='active ? "has-text-white" : "has-text-info"') Model
      b-icon(:icon='active ? "caret-up" : "caret-down"', :type='active ? "is-white" : "is-info"')

    b-dropdown-item.is-marginless.is-paddingless(:focusable='false', custom)
      AutodeskHubs(@selected='onModelSelected')
  p.control
    span.button.is-static Currently presenting
  b-dropdown(v-if='meet.owner === $auth.currentUser.uid', @change='onPresenterChange')
    b-button(icon-right='chevron-down', slot='trigger') {{ meet.presenter }}
    b-dropdown-item(:value='$auth.currentUser.email') {{ $auth.currentUser.email }}
    b-dropdown-item(:value='guest', v-for='(guest, index) in meet.guests', :key='index') {{ guest }}
  b-input(:value='meet.presenter', v-else, disabled)
</template>

<script>
export default {
  name: 'TopLeftMenu',
  props: ['meet', 'userIsOwner', 'id'],
  components: {
    AutodeskHubs: () =>
      import(/* webpackPrefetch: true */ '@/components/autodeskForge/AutodeskHubs.vue')
  },
  methods: {
    onModelSelected(model, { attributes }) {
      console.log('modelSelected', model, attributes)
      const { containerId, folderId, hubId, itemId, projectId } = attributes

      this.$store.dispatch('selectedModelData', { containerId, folderId, hubId, itemId, projectId })

      this.updateMeet({
        modelURN: model,
        containerId,
        folderId,
        hubId,
        itemId,
        projectId,
        viewableId: null
      })
        .then(() => {
          this.$log('success', ' Successfully updated meeting', {
            model,
            ...attributes
          })
          this.$store.dispatch('updateMeeting', { model, ...attributes })
        })
        .catch(err => console.error(err))
    },
    /** @param value User email */
    onPresenterChange(value) {
      this.updateMeet({ presenter: value })
        .then(() => {
          this.$emit('setstate-sub')
        })
        .catch(err => {
          console.error('error while updating meet on presenter change', err)
        })
    },
    updateMeet(data) {
      return this.$firestore.doc(`meets/${this.id}`).update(data)
    }
  }
}
</script>

<style lang="scss" scoped></style>
