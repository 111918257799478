import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
var TwilioMedia = (function (_super) {
    __extends(TwilioMedia, _super);
    function TwilioMedia() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = true;
        _this.audioActive = true;
        _this.videoActive = true;
        return _this;
    }
    TwilioMedia.prototype.getButtonType = function (publication) {
        var track = null;
        if (publication[1].isSubscribed === undefined) {
            var local = publication[1];
            track = local.track;
        }
        else {
            var remote = publication[1];
            if (publication.isSubscribed == false)
                return 'is-danger';
            track = remote.track;
        }
        if (track === null)
            return 'is-danger';
        return (track.kind === 'video' && this.videoActive) ||
            (track.kind === 'audio' && this.audioActive)
            ? 'is-success'
            : 'is-danger';
    };
    TwilioMedia.prototype.getButtonIcon = function (publication) {
        var track = publication[1].track;
        if (track === null)
            return 'ban';
        if (publication.track == 'video' || track.kind == 'video')
            return this.videoActive ? 'video' : 'video-slash';
        if (track.kind == 'audio')
            return this.audioActive ? 'microphone-alt' : 'microphone-alt-slash';
    };
    TwilioMedia.prototype.toggleTrack = function (publication) {
        if (this.user.identity !== this.$auth.currentUser.email)
            return;
        var track = publication[1].track;
        if (track.isEnabled) {
            track.disable();
        }
        else {
            track.enable();
        }
    };
    TwilioMedia.prototype.mounted = function () {
        var _this = this;
        this.user.tracks.forEach(function (publication) { return _this.registerPublicaton(publication); });
        this.user.on('trackSubscribed', this.onTrackSubscribed);
        this.user.on('trackEnabled', function (e) {
            if (e.kind == 'video')
                _this.videoActive = true;
            if (e.kind == 'audio')
                _this.audioActive = true;
        });
        this.user.on('trackDisabled', function (e) {
            if (e.kind == 'video')
                _this.videoActive = false;
            if (e.kind == 'audio')
                _this.audioActive = false;
        });
        this.loading = false;
    };
    TwilioMedia.prototype.destroyed = function () {
        console.log('destroying twilio-media', this.user.identity);
        this.user.removeAllListeners();
        this.user.tracks.forEach(function (publication) {
            console.log(publication);
            var track = publication === null || publication === void 0 ? void 0 : publication.track;
            console.log('track', track);
            if (track) {
                track.removeAllListeners();
            }
            console.log('track', track);
        });
    };
    TwilioMedia.prototype.registerPublicaton = function (publication) {
        if (publication.track) {
            this.userMedia.appendChild(publication.track.attach());
        }
    };
    TwilioMedia.prototype.onTrackSubscribed = function (track) {
        var _a;
        (_a = this.userMedia) === null || _a === void 0 ? void 0 : _a.appendChild(track.attach());
        this.$forceUpdate();
    };
    Object.defineProperty(TwilioMedia.prototype, "cssVars", {
        get: function () {
            return {
                '--user-color': this.color,
                '--video-width': this.width,
                '--video-height': this.height
            };
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop()
    ], TwilioMedia.prototype, "user", void 0);
    __decorate([
        Prop({ default: '#000000' })
    ], TwilioMedia.prototype, "color", void 0);
    __decorate([
        Prop({ default: '160px' })
    ], TwilioMedia.prototype, "width", void 0);
    __decorate([
        Prop({ default: '120px' })
    ], TwilioMedia.prototype, "height", void 0);
    __decorate([
        Ref()
    ], TwilioMedia.prototype, "userMedia", void 0);
    TwilioMedia = __decorate([
        Component
    ], TwilioMedia);
    return TwilioMedia;
}(Vue));
export default TwilioMedia;
