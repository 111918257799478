<template lang="pug">
b-field.bottom-right(horizontal)
  BButton(@click='snapShot') Screenshot

  b-dropdown(
    position='is-top-left',
    :class='{ "is-hidden": !userIsOwner || !forgeViewer, "is-disabled": meet.containerId === undefined }',
    :max-height='400',
    :mobile-modal='false',
    scrollable
  )
    button.button(slot='trigger', slot-scope='{ active }', :class='active ? "is-info" : ""')
      span(:class='active ? "has-text-white" : "has-text-info"') Viewables
      b-icon(:icon='active ? "caret-down" : "caret-up"', :type='active ? "is-white" : "is-info"')

    b-dropdown-item(
      aria-role='listitem',
      v-for='item in modelViewables',
      :key='item.data.guid',
      :focusable='false',
      @click='onViewableSelected(item)'
    )
      .media
        b-icon.media-left(
          :icon='item.data.role === "3d" ? "cube" : "ruler-combined"',
          size='is-small'
        )
        .media-content
          span {{ item.data.name }}

  BButton(@click='isComponentModalActive = true', v-if='meet.owner === $auth.currentUser.uid') Guests
  BModal.guest-modal(has-modal-card, :active.sync='isComponentModalActive')
    InviteModal(@guest-modified='onGuestModified', v-bind='$props')
  b-dropdown(
    position='is-top-left',
    :close-on-click='false',
    :max-height='400',
    :can-close='false',
    :mobile-modal='false',
    :class='{ "is-hidden": !userIsOwner || !forgeViewer, "is-disabled": meet.containerId === undefined }'
  )
    button#issuesbtn.button(
      slot='trigger',
      slot-scope='{ active }',
      :class='active ? "is-info" : ""'
    )
      span(:class='active ? "has-text-white" : "has-text-info"') Issues
      b-icon(:icon='active ? "caret-down" : "caret-up"', :type='active ? "is-white" : "is-info"')

    b-dropdown-item(
      v-if='meet.containerId && forgeViewer !== null && meet.itemId && progress > 0',
      :focusable='false',
      custom
    )
      bim360-issue-viewer(
        :container-id='meet.containerId',
        :viewer='forgeViewer',
        :model-urn='meet.itemId',
        :current-state='currentState',
        :meet-id='id',
        :screen-shot='screenShot'
      )
  canvas#snapshot(style='position: absolute')
</template>

<script>
import InviteModal from '@/components/meeting/BottomRightMenu/InviteModal'

export default {
  name: 'BottomRightMenu',
  components: {
    Bim360IssueViewer: () =>
      import(/* webpackPrefetch: true */ '@/components/autodeskForge/Bim360IssueViewer.vue'),
    InviteModal
  },
  props: [
    'userIsOwner',
    'forgeViewer',
    'meet',
    'progress',
    'current-state',
    'id',
    'modelViewables'
  ],
  data() {
    return {
      isComponentModalActive: false,
      screenShot: ''
    }
  },
  mounted() {
    this.$root.$on('createScreenshot', () => {
      this.snapShot()
      this.showIssuesDropdown()
      this.$root.$emit('pasteScreenshot')
    })
  },
  methods: {
    updateMeet(data) {
      console.log('updateMeet -> data', data)
      return this.$firestore.doc(`meets/${this.id}`).update(data)
    },
    onViewableSelected(viewable) {
      console.log('viewable selected', viewable.data.name)
      this.updateMeet({ viewableId: viewable.data.guid })
    },
    /**
     * Handle guest list modifications.
     */

    onGuestModified(value) {
      console.log('guests modified', value)
      this.updateMeet({ guests: value })
        .then(() => console.log('Successfully updated guests'))
        .catch(err => console.error(err))
    },

    snapShot() {
      this.screenShotLoad()
      this.showIssuesDropdown()
    },

    screenShotLoad() {
      let viewer = this.forgeViewer.viewer
      const screenshotAux = new Image()
      screenshotAux.onload = () => {
        let sData = ''
        viewer.loadExtension('Autodesk.Viewing.MarkupsCore').then(markupCore => {
          let canvas = document.getElementById('snapshot')
          canvas.width = viewer.container.clientWidth
          canvas.height = viewer.container.clientHeight

          let ctx = canvas.getContext('2d')
          ctx.clearRect(0, 0, canvas.width, canvas.height)
          ctx.drawImage(screenshotAux, 0, 0, canvas.width, canvas.height)

          if (markupCore.markups.length) {
            markupCore.renderToCanvas(ctx, () => {
              sData = canvas.toDataURL('image/png')
              this.screenShot = sData
              console.log('screenShot', this.screenShot)
              console.log('canvas', canvas.width)
              this.$root.$emit('pasteScreenshot', sData)
            })
          }
        })
      }

      // Get the full image
      viewer.getScreenShot(viewer.container.clientWidth, viewer.container.clientHeight, blobURL => {
        this.screenShot = blobURL
        screenshotAux.src = blobURL
      })
    },

    showIssuesDropdown() {
      const issuesBtn = document.getElementById('issuesbtn')
      issuesBtn.click()
    }
  }
}
</script>

<style lang="scss" scoped>
.guest-modal {
  z-index: 1000;
  cursor: default !important;
}

.dropdown-content {
  padding-top: 0 !important;
  margin-bottom: 0.5rem;
}
</style>
