import { __awaiter, __decorate, __extends, __generator } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Hero from '@/components/bulma/Hero.vue';
import { pollAudioLevel } from '@/components/twilio/pollaudiolevel';
import { createLocalAudioTrack, createLocalVideoTrack } from 'twilio-video';
import VolumeIndicator from '@/components/twilio/VolumeIndicator.vue';
var MediaSelect = (function (_super) {
    __extends(MediaSelect, _super);
    function MediaSelect() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.selectedVideo = { label: '' };
        _this.selectedAudio = { label: '' };
        _this.videoInputs = null;
        _this.audioInputs = null;
        _this.localAudioTrack = null;
        _this.localVideoTrack = null;
        _this.videoElement = null;
        _this.audioElement = null;
        _this.audioLevel = 0;
        return _this;
    }
    MediaSelect.prototype.onAudioDeviceChanged = function (audioInput) {
        this.selectedAudio = audioInput;
        if (this.localAudioTrack !== null) {
            this.localAudioTrack.detach(this.audioElement);
            this.audioElement = null;
        }
        this.setupAudioTrack();
    };
    MediaSelect.prototype.onVideoDeviceChanged = function (videoInput) {
        var _a;
        this.selectedVideo = videoInput;
        if (this.localAudioTrack !== null) {
            (_a = this.localVideoTrack) === null || _a === void 0 ? void 0 : _a.detach(this.videoElement);
            document.getElementById('local-media').removeChild(this.videoElement);
            this.videoElement = null;
        }
        this.setupVideoTrack();
    };
    MediaSelect.prototype.beforeMount = function () {
        var _this = this;
        return navigator.mediaDevices.enumerateDevices().then(function (devices) {
            _this.videoInputs = devices.filter(function (device) { return device.kind === 'videoinput'; });
            _this.selectedVideo = _this.videoInputs.length > 0 ? _this.videoInputs[0] : null;
            if (_this.selectedVideo)
                _this.onVideoDeviceChanged(_this.selectedVideo);
            _this.audioInputs = devices.filter(function (device) { return device.kind === 'audioinput'; });
            _this.selectedAudio = _this.audioInputs.length > 0 ? _this.audioInputs[0] : null;
            if (_this.selectedAudio)
                _this.onAudioDeviceChanged(_this.selectedAudio);
        });
    };
    MediaSelect.prototype.beforeDestroy = function () {
        this.localVideoTrack.stop();
        this.localVideoTrack.detach(this.videoElement);
        this.localAudioTrack.stop();
        this.localAudioTrack.detach(this.audioElement);
    };
    MediaSelect.prototype.setupTracks = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, Promise.all([this.setupAudioTrack(), this.setupVideoTrack()])];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    MediaSelect.prototype.setupVideoTrack = function () {
        var _this = this;
        var _a;
        return createLocalVideoTrack({
            deviceId: (_a = this.selectedVideo) === null || _a === void 0 ? void 0 : _a.deviceId,
            width: 400,
            height: 300
        })
            .then(function (videoTrack) {
            _this.localVideoTrack = videoTrack;
            _this.videoElement = videoTrack.attach();
            document.getElementById('local-media').appendChild(_this.videoElement);
        })
            .catch(function (err) { return console.error('video track error', err); });
    };
    MediaSelect.prototype.setupAudioTrack = function () {
        var _this = this;
        var _a;
        return createLocalAudioTrack({
            deviceId: (_a = this.selectedAudio) === null || _a === void 0 ? void 0 : _a.deviceId
        })
            .then(function (audioTrack) {
            _this.localAudioTrack = audioTrack;
            pollAudioLevel(audioTrack, function (level) {
                _this.audioLevel = level;
            });
            _this.audioElement = audioTrack.attach();
            document.getElementById('local-media').appendChild(_this.audioElement);
        })
            .catch(function (err) { return console.error('audio track error', err); });
    };
    MediaSelect = __decorate([
        Component({
            components: { VolumeIndicator: VolumeIndicator, Hero: Hero }
        })
    ], MediaSelect);
    return MediaSelect;
}(Vue));
export default MediaSelect;
