import { __awaiter, __decorate, __extends, __generator } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import RoomEvents from '@/components/twilio/TwilioRoom/roomEvents';
import TwilioMedia from '@/components/twilio/TwilioMedia.vue';
import DraggableDiv from '@/components/twilio/TwilioRoom/DraggableDiv.vue';
import LogoBimMeet from '@/components/common/LogoBimMeet.vue';
import ChatWidget from '@/components/twilio/ChatWidget.vue';
import { colors } from '@/components/twilio/TwilioRoom/colors';
var TwilioRoom = (function (_super) {
    __extends(TwilioRoom, _super);
    function TwilioRoom() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.participantPics = {};
        _this.room = null;
        _this.participants = [];
        _this.colors = colors;
        _this.videoWidth = 300;
        _this.videoHeight = 180;
        _this.hiddenMedia = false;
        _this.showChat = false;
        return _this;
    }
    TwilioRoom.prototype.mounted = function () {
        var _this = this;
        var _a = this, options = _a.options, id = _a.id, $auth = _a.$auth, $store = _a.$store;
        $store.commit('setTwilioBaseState', { options: options, id: id });
        $store.dispatch('getTwilioToken', id);
        var subs = $store.subscribe(function (mutation, state) {
            if (mutation.type === 'setTwilioRoom' && !!mutation.payload) {
                _this.setupVideoCall(mutation.payload);
            }
        });
        this.userPic($auth.currentUser.email).then(function (url) { return (_this.participantPics[$auth.currentUser.email] = url); });
    };
    Object.defineProperty(TwilioRoom.prototype, "openedFeatureStyle", {
        get: function () {
            return 'background-color: #d7d7d748; color: #32C2CA !important;';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TwilioRoom.prototype, "getLoading", {
        get: function () {
            return this.$apollo.loading;
        },
        enumerable: false,
        configurable: true
    });
    TwilioRoom.prototype.userPic = function (email) {
        return __awaiter(this, void 0, void 0, function () {
            var userPicFunc, picURL;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        userPicFunc = this.$functions.httpsCallable('userPic');
                        return [4, userPicFunc({ email: email }).then(function (result) {
                                console.log('received user pic', result.data);
                                return result.data;
                            })];
                    case 1:
                        picURL = _a.sent();
                        return [2, picURL];
                }
            });
        });
    };
    TwilioRoom.prototype.handleDrag = function (_a) {
        var target = _a.target, transform = _a.transform;
        target.style.transform = transform;
    };
    TwilioRoom.prototype.toggleChat = function () {
        this.showChat = !this.showChat;
        this.relocatePosition();
    };
    TwilioRoom.prototype.toggleMedia = function () {
        this.hiddenMedia = !this.hiddenMedia;
        this.relocatePosition();
    };
    TwilioRoom.prototype.relocatePosition = function () {
        var DraggeableDiv = this.$refs.DraggableDiv;
        var _a = DraggeableDiv.$refs.draggableContainer, offsetTop = _a.offsetTop, offsetLeft = _a.offsetLeft;
        this.$nextTick(function () {
            DraggeableDiv.filterPosition({
                offsetTop: offsetTop - 1,
                offsetLeft: offsetLeft
            });
        });
    };
    TwilioRoom.prototype.toggleUserPointer = function (ev) {
        var _a, _b;
        var localUser = (_b = (_a = this.room) === null || _a === void 0 ? void 0 : _a.localParticipant) === null || _b === void 0 ? void 0 : _b.identity;
        var localUserPointer = document.getElementById("pointer-" + localUser);
        if (localUser && localUserPointer) {
            ev === 'enter'
                ? (localUserPointer.style.opacity = '0')
                : (localUserPointer.style.opacity = '1');
        }
    };
    __decorate([
        Prop()
    ], TwilioRoom.prototype, "id", void 0);
    __decorate([
        Prop()
    ], TwilioRoom.prototype, "mousePositions", void 0);
    __decorate([
        Prop()
    ], TwilioRoom.prototype, "options", void 0);
    TwilioRoom = __decorate([
        Component({
            components: {
                DraggableDiv: DraggableDiv,
                TwilioMedia: TwilioMedia,
                LogoBimMeet: LogoBimMeet,
                ChatWidget: ChatWidget
            },
            mixins: [RoomEvents]
        })
    ], TwilioRoom);
    return TwilioRoom;
}(Vue));
export default TwilioRoom;
