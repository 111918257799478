var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("@/assets/images/bim-meet-icon.png") }
      }),
      _c("span", { staticClass: "has-text-info has-text-weight-medium" }, [
        _vm._v("BIM")
      ]),
      _c("span", { staticClass: "has-text-primary has-text-weight-bold" }, [
        _vm._v("Meet")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }