var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "card-content" },
      [
        _c("p", { staticClass: "subtitle is-7" }, [
          _vm._v("Only the following users will have access to this meeting.")
        ]),
        _c("BTaginput", {
          staticClass: "Modal-spacing",
          attrs: {
            placeholder: "Invite people to this meet",
            icon: "user",
            maxtags: "3"
          },
          on: {
            input: function($event) {
              return _vm.$emit("guest-modified", _vm.meet.guests)
            }
          },
          model: {
            value: _vm.meet.guests,
            callback: function($$v) {
              _vm.$set(_vm.meet, "guests", $$v)
            },
            expression: "meet.guests"
          }
        }),
        _c(
          "BTooltip",
          {
            attrs: {
              label: "Public meets will be allowed soon!",
              position: "is-bottom"
            }
          },
          [
            _c("BCheckbox", { attrs: { size: "is-small", disabled: "" } }, [
              _vm._v("Make this meeting public?")
            ])
          ],
          1
        ),
        _c("hr"),
        _c("p", { staticClass: "subtitle is-7" }, [
          _vm._v(
            "Share the code or the direct link to this meet with your guests!"
          )
        ]),
        _c(
          "BField",
          { staticClass: "Modal-spacing" },
          [
            _c("p", { staticClass: "control" }, [
              _c("span", { staticClass: "button is-static" }, [
                _vm._v("Meet code")
              ])
            ]),
            _c("BInput", { attrs: { placeholder: _vm.id, expanded: "" } }),
            _c(
              "button",
              {
                staticClass: "button is-primary clipboard-btn",
                attrs: { "data-clipboard-text": _vm.id }
              },
              [_vm._v("Copy")]
            )
          ],
          1
        ),
        _c(
          "BField",
          [
            _c("p", { staticClass: "control" }, [
              _c("span", { staticClass: "button is-static" }, [
                _vm._v("Meet url")
              ])
            ]),
            _c("BInput", { attrs: { placeholder: _vm.meetUrl, expanded: "" } }),
            _c(
              "button",
              {
                staticClass: "button is-primary clipboard-btn",
                attrs: { "data-clipboard-text": _vm.meetUrl }
              },
              [_vm._v("Copy")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("div", { staticClass: "card-header-title" }, [
        _c("p", [_vm._v("Invite guests to this meet!")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }