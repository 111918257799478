import { __awaiter, __generator } from "tslib";
import { apollo } from '@/apolloProvider';
var apolloApi = {
    sendPosition: function (pos, userId, meetId) {
        return __awaiter(this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, apollo.mutate({
                                mutation: require('../../graphql/SubmitPosition.gql'),
                                variables: { pos: pos, userId: userId, meetId: meetId }
                            })];
                    case 1:
                        _a.sent();
                        return [3, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.log('Could not send position', pos, error_1);
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    },
    sendViewerState: function (state, meetId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                try {
                    apollo.mutate({
                        mutation: require('../../graphql/SubmitViewerState.gql'),
                        variables: { state: state, meetId: meetId }
                    });
                }
                catch (err) {
                    console.log('error sending state', err);
                }
                return [2];
            });
        });
    }
};
export default apolloApi;
