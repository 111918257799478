var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.rowAccordingGuest() }, [
    _c("div", { class: _vm.containerAccordingGuest() }, [
      _vm.isMessageFromGuest
        ? _c("p", { staticClass: "chat__name" }, [
            _vm._v(" " + _vm._s(_vm.content.name) + " ")
          ])
        : _vm._e(),
      _c(
        "p",
        {
          staticClass: "chat__content",
          class: _vm.colorAccordingGuest(),
          attrs: { draggable: "false" }
        },
        [_vm._v(" " + _vm._s(_vm.content.message) + " ")]
      ),
      _c(
        "div",
        { staticClass: "chat__time", style: _vm.timeColorAccordingGuest() },
        [_vm._v(" " + _vm._s(_vm.getTime()) + " ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }