import { __decorate, __extends } from "tslib";
import { Vue, Component, Watch, Prop, Emit } from 'vue-property-decorator';
var StyleObjects = (function (_super) {
    __extends(StyleObjects, _super);
    function StyleObjects() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.COLOR_LINE = 'line';
        _this.COLOR_FILL = 'fill';
        _this.pressedLine = 0;
        _this.pressedFill = 0;
        _this.lineWidth = 10;
        _this.opacity = 50;
        return _this;
    }
    Object.defineProperty(StyleObjects.prototype, "colors", {
        get: function () {
            return [
                '#FF0000',
                '#103FFB',
                '#922890',
                '#FEF935',
                '#FF9224',
                '#03FCFE',
                '#FF4CFC',
                '#00F72C',
                '#000000',
                '#FFFFFF'
            ];
        },
        enumerable: false,
        configurable: true
    });
    StyleObjects.prototype.mounted = function () { };
    StyleObjects.prototype.setLineColor = function (_a) {
        var target = _a.target;
        this.pressedLine = target.getAttribute('index');
        this.Color.Line = target.getAttribute('fill');
        this.emitColor();
    };
    StyleObjects.prototype.setFillColor = function (_a) {
        var target = _a.target;
        this.pressedFill = target.getAttribute('index');
        this.Color.Fill = target.getAttribute('fill');
        var opacityObj = document.querySelector('#opacity');
        opacityObj.removeAttribute('opacity');
        this.Color.Opacity = this.opacity + '%';
        if (this.colors.length == this.pressedFill) {
            this.Color.Opacity = 0;
            opacityObj.setAttribute('disabled', 'disabled');
        }
        this.emitColor();
    };
    StyleObjects.prototype.setWidthColor = function () {
        this.Color.Width = this.lineWidth;
        this.emitColor();
    };
    StyleObjects.prototype.setOpacityColor = function () {
        this.Color.Opacity = this.opacity + '%';
        this.emitColor();
    };
    StyleObjects.prototype.subMenuItems = function (_a) {
        var target = _a.target;
        var parent = target.parentNode;
        this.showHideSubMenuItem(parent.getAttribute('parent'));
    };
    StyleObjects.prototype.showHideSubMenuItem = function (type) {
        var element = document.getElementsByClassName(type).item(0);
        var hiddenClass = 'hidden';
        if (element.classList.contains(hiddenClass)) {
            element.classList.remove(hiddenClass);
            var li = document.querySelector('#Markup').querySelectorAll(".sub-items:not(." + type + ")");
            li.forEach(function (_a) {
                var classList = _a.classList;
                return classList.add(hiddenClass);
            });
        }
        else {
            element.classList.add(hiddenClass);
        }
    };
    StyleObjects.prototype.onLineWidthChange = function () {
        this.setWidthColor();
    };
    StyleObjects.prototype.onOpacityChange = function () {
        this.setOpacityColor();
    };
    StyleObjects.prototype.emitColor = function () {
        return this.Color;
    };
    __decorate([
        Prop()
    ], StyleObjects.prototype, "Color", void 0);
    __decorate([
        Watch('lineWidth')
    ], StyleObjects.prototype, "onLineWidthChange", null);
    __decorate([
        Watch('opacity')
    ], StyleObjects.prototype, "onOpacityChange", null);
    __decorate([
        Emit()
    ], StyleObjects.prototype, "emitColor", null);
    StyleObjects = __decorate([
        Component
    ], StyleObjects);
    return StyleObjects;
}(Vue));
export default StyleObjects;
