var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { ref: "slideMenu", staticClass: "ViewerDrawer card" },
      [
        !_vm.showDrawerMenu
          ? _c(
              "b-tooltip",
              { attrs: { label: "Draw", position: "is-left" } },
              [
                _c("BButton", {
                  staticClass: "ViewerDrawer-showbtn",
                  attrs: { "icon-left": "pen" },
                  on: {
                    click: function($event) {
                      _vm.setDrawMode()
                      _vm.animateMenu()
                    }
                  }
                })
              ],
              1
            )
          : _c(
              "BTooltip",
              { attrs: { label: "Close", position: "is-left" } },
              [
                _c("BButton", {
                  staticClass: "ViewerDrawer-showbtn",
                  attrs: { "icon-left": "times" },
                  on: {
                    click: function($event) {
                      return _vm.returnToViewer()
                    }
                  }
                })
              ],
              1
            ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showDrawerMenu,
                expression: "showDrawerMenu"
              }
            ],
            staticClass: "ViewerDrawer-toolbar"
          },
          [
            _c("BTooltip", { attrs: { label: "Save", position: "is-left" } }, [
              _c(
                "a",
                {
                  staticClass: "download-draw button",
                  attrs: { href: _vm.downloadImgUrl, download: "" },
                  on: {
                    click: function($event) {
                      return _vm.saveImgToCloud()
                    }
                  }
                },
                [_c("BIcon", { attrs: { icon: "check", size: "is-small" } })],
                1
              )
            ]),
            _c("BTooltip", { attrs: { label: "Color", position: "is-left" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.activeColor,
                    expression: "activeColor"
                  }
                ],
                staticClass: "ViewerDrawer-color",
                attrs: { type: "color" },
                domProps: { value: _vm.activeColor },
                on: {
                  change: function($event) {
                    return _vm.setCanvasOptions({
                      strokeStyle: _vm.activeColor,
                      fillStyle: _vm.activeColor
                    })
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.activeColor = $event.target.value
                  }
                }
              })
            ]),
            _c(
              "BTooltip",
              { attrs: { label: "Draw", position: "is-left" } },
              [
                _c("BButton", {
                  attrs: {
                    "icon-left": "pen",
                    type: _vm.mode === "draw" ? "is-primary" : ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.setMode("draw", "none")
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "BTooltip",
              { attrs: { label: "Stroke size", position: "is-left" } },
              [
                _c("BButton", {
                  attrs: { "icon-left": "circle" },
                  on: {
                    click: function($event) {
                      _vm.showSlider = !_vm.showSlider
                    }
                  }
                })
              ],
              1
            ),
            _vm.showSlider
              ? _c("BSlider", {
                  on: {
                    change: function($event) {
                      _vm.setCanvasOptions({ lineWidth: _vm.lineWidth })
                      _vm.setPenSize(_vm.lineWidth)
                    }
                  },
                  model: {
                    value: _vm.lineWidth,
                    callback: function($$v) {
                      _vm.lineWidth = $$v
                    },
                    expression: "lineWidth"
                  }
                })
              : _vm._e(),
            _c(
              "BTooltip",
              { attrs: { label: "Draw lines", position: "is-left" } },
              [
                _c("BButton", {
                  attrs: {
                    "icon-left": "grip-lines-vertical",
                    type: _vm.mode === "line" ? "is-primary" : ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.setMode("line", "pointer")
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "BTooltip",
              { attrs: { label: "Text", position: "is-left" } },
              [
                _c("BButton", {
                  attrs: {
                    "icon-left": "paragraph",
                    type: _vm.mode === "write" ? "is-primary" : ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.setMode("write", "text")
                    }
                  }
                }),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.text,
                      expression: "text"
                    },
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.writingModal,
                      expression: "writingModal"
                    }
                  ],
                  style: _vm.textAreaStyles,
                  attrs: { cols: "20", rows: "3" },
                  domProps: { value: _vm.text },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.setText($event)
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.text = $event.target.value
                    }
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showDrawerMenu && _vm.mode === "draw",
          expression: 'showDrawerMenu && mode === "draw"'
        }
      ],
      ref: "pen",
      staticClass: "pen",
      style: { opacity: _vm.showPen },
      attrs: { id: "pen" }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }