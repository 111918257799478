var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "twilio-media", style: _vm.cssVars },
    [
      _c(
        "div",
        { staticClass: "track-buttons" },
        _vm._l(_vm.user.tracks, function(track, index) {
          return _c("b-button", {
            key: index,
            attrs: {
              "icon-left": _vm.getButtonIcon(track),
              size: "is-small",
              type: _vm.getButtonType(track),
              disabled: _vm.user.identity !== _vm.$auth.currentUser.email,
              rounded: ""
            },
            on: {
              click: function($event) {
                return _vm.toggleTrack(track)
              }
            }
          })
        }),
        1
      ),
      _c("p", { staticClass: "user-name has-text-white is-size-7" }, [
        _vm._v(_vm._s(_vm.user.identity))
      ]),
      _c("div", { ref: "userMedia", staticClass: "media" }),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }