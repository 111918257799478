<template>
  <BLoading class="LoadingMeet" :active="!meet || fetching || loading" :can-cancel="false">
    <div class="progress-container">
      <div class="has-text-weight-bold has-text-centered" v-if="hasErrors">
        <img src="@/assets/images/broken-house-svgrepo-com.svg" class="broken-house" />
        <p class="title is-4 has-text-danger" v-for="(error, index) in errors" :key="index">
          {{ error.message }}
        </p>
      </div>
      <div v-else>
        <p class="has-text-primary has-text-weight-bold">
          {{ !progress ? 'Getting meeting details...' : 'Loading meeting model....' }}
        </p>
        <BProgress :value="progress" show-value, format="percent" type="is-primary" />
      </div>
    </div>
  </BLoading>
</template>

<script>
export default {
  props: ['meet', 'fetching', 'loading', 'hasErrors', 'progress', 'errors'],
  name: 'LoadingMeet'
}
</script>

<style lang="scss" scoped>
.LoadingMeet {
  background-color: white !important;
  .progress-container {
    min-width: 20%;
  }
}
.broken-house {
  max-height: 16em;
}
</style>
