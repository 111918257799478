var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.$auth.currentUser
    ? _c("LoginRequired")
    : !_vm.hasUserApproval
    ? _c(
        "div",
        { staticClass: "approval-container" },
        [
          _c("MediaSelect", {
            on: {
              cancel: function($event) {
                return _vm.$router.push("/")
              },
              join: _vm.onUserJoin
            }
          })
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "meeting-container" },
        [
          _c("LoadingMeet", {
            attrs: {
              meet: _vm.meet,
              fetching: _vm.fetching,
              loading: _vm.loading,
              hasErrors: _vm.hasErrors,
              progress: _vm.progress,
              errors: _vm.errors
            }
          }),
          _vm.errors.length === 0 && _vm.meet
            ? _c(
                "div",
                {
                  staticClass: "meeting",
                  on: { mousemove: _vm.onMouseUpdate }
                },
                [
                  _c("TwilioRoom", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.$store.state.modal.twilioCard,
                        expression: "!$store.state.modal.twilioCard"
                      }
                    ],
                    staticClass: "bottom-left",
                    attrs: {
                      id: _vm.id,
                      "mouse-positions": _vm.receivedPositions,
                      options: _vm.twilioOptions
                    },
                    on: {
                      error: _vm.onRoomError,
                      connected: _vm.onRoomConnected
                    }
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$store.state.modal.twilioCard,
                          expression: "$store.state.modal.twilioCard"
                        }
                      ],
                      staticClass: "TwilioMinimized card"
                    },
                    [
                      _c("LogoBimMeet", { staticClass: "LogoBimMeet" }),
                      _c("BIcon", {
                        staticClass: "minimize-icon",
                        attrs: { icon: "window-maximize" },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$store.commit("setModalState", false)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm.isModelLoaded ? _c("Markup") : _vm._e(),
                  _vm.hasBeenLoaded
                    ? _c("BottomRightMenu", {
                        staticClass: "bottom-right",
                        attrs: {
                          userIsOwner: _vm.userIsOwner,
                          forgeViewer: _vm.forgeViewer,
                          meet: _vm.meet,
                          progress: _vm.progress,
                          id: _vm.id,
                          "current-state": _vm.currentState,
                          modelViewables: _vm.modelViewables
                        }
                      })
                    : _vm._e(),
                  _vm._l(_vm.participants, function(participant, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        !_vm.$auth.currentUser ||
                        participant.identity != _vm.$auth.currentUser.email
                          ? _c("UserPointer", {
                              staticClass: "user-pointer",
                              attrs: {
                                "user-id": participant.identity,
                                position: _vm.receivedPositions
                                  ? _vm.receivedPositions[participant.identity]
                                  : { x: 1000, y: 1000 },
                                color: _vm.colors[index + 1]
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  }),
                  !_vm.loading
                    ? _c("TopLeftMenu", {
                        staticClass: "TopLeftMenu",
                        attrs: {
                          meet: _vm.meet,
                          userIsOwner: _vm.userIsOwner,
                          id: _vm.id
                        },
                        on: { "setstate-sub": _vm.setupStateSubscription }
                      })
                    : _vm._e(),
                  _vm.meet.modelURN
                    ? _c("ForgeViewer", {
                        ref: "forgeViewer",
                        staticClass: "viewer",
                        attrs: {
                          "state-filter": _vm.forgeViewerStateFilter,
                          "model-u-r-n": _vm.meet.modelURN,
                          "viewable-id": _vm.meet.viewableId || "",
                          "cam-lock": !_vm.userIsPresenter
                        },
                        on: {
                          "state-changed": _vm.onViewerStateChanged,
                          "geometry-loaded": _vm.loadComplete,
                          "progress-update": _vm.loadProgress,
                          "get-access-token": function($event) {
                            return _vm.getForgeToken($event)
                          },
                          "viewables-ready": _vm.onViewablesReady
                        }
                      })
                    : _vm._e(),
                  _c("div", { staticClass: "select-model-text" }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.userIsOwner
                            ? "Please select a model to view"
                            : "Meeting owner hasn't selected a model yet."
                        )
                      )
                    ])
                  ])
                ],
                2
              )
            : _vm._e()
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }