var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Hero",
    { attrs: { color: "clear login-container" } },
    [
      _c("p", { staticClass: "title has-text-weight-normal is-4" }, [
        _vm._v(" You must be logged in in order to access meeting "),
        _c(
          "span",
          {
            staticClass: "is-italic has-text-primary has-text-weight-semibold"
          },
          [_vm._v("login " + _vm._s(_vm.id))]
        )
      ]),
      _c("Login")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }