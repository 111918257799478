import { __awaiter, __generator } from "tslib";
var audioContext = window.AudioContext ? new window.AudioContext() : null;
var rootMeanSquare = function (samples) {
    var sumSq = samples.reduce(function (sumSq, sample) { return sumSq + sample * sample; }, 0);
    return Math.sqrt(sumSq / samples.length);
};
export var pollAudioLevel = function (track, onLevelChanged) {
    return __awaiter(void 0, void 0, void 0, function () {
        var analyser, stream, source, samples, level;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!audioContext) {
                        return [2];
                    }
                    return [4, audioContext.resume()];
                case 1:
                    _a.sent();
                    analyser = audioContext.createAnalyser();
                    analyser.fftSize = 1024;
                    analyser.smoothingTimeConstant = 0.5;
                    stream = new MediaStream([track.mediaStreamTrack]);
                    source = audioContext.createMediaStreamSource(stream);
                    source.connect(analyser);
                    samples = new Uint8Array(analyser.frequencyBinCount);
                    level = null;
                    requestAnimationFrame(function checkLevel() {
                        analyser.getByteFrequencyData(samples);
                        var rms = rootMeanSquare(samples);
                        var log2Rms = rms && Math.log2(rms);
                        var newLevel = Math.ceil((10 * log2Rms) / 8);
                        if (level !== newLevel) {
                            level = newLevel;
                            onLevelChanged(level);
                        }
                        if (track.mediaStreamTrack.readyState === 'live') {
                            requestAnimationFrame(checkLevel);
                        }
                        else {
                            requestAnimationFrame(function () { return onLevelChanged(0); });
                        }
                    });
                    return [2];
            }
        });
    });
};
