var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "meet-preface" },
    [
      _c("hero", { attrs: { color: "clear" } }, [
        _c("div", { staticClass: "content has-text-centered" }, [
          _c(
            "h1",
            { staticClass: "is-size-4 has-text-grey has-text-weight-normal" },
            [
              _vm._v("Select media join the "),
              _c(
                "span",
                { staticClass: "has-text-info has-text-weight-medium" },
                [_vm._v("BIM")]
              ),
              _c(
                "span",
                { staticClass: "has-text-primary has-text-weight-bold" },
                [_vm._v("Meet")]
              )
            ]
          )
        ]),
        _c("div", { staticClass: "columns is-vcentered" }, [
          _c(
            "div",
            { staticClass: "column is-6" },
            [
              _c("p", [_vm._v("Video")]),
              _vm.videoInputs !== null && _vm.videoInputs.length > 0
                ? _c(
                    "b-dropdown",
                    {
                      attrs: {
                        value: _vm.selectedVideo.label,
                        "aria-role": "list",
                        expanded: ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "trigger",
                            fn: function(ref) {
                              var active = ref.active
                              return _c(
                                "b-button",
                                {
                                  staticClass: "media-input",
                                  attrs: { "icon-left": "video", expanded: "" }
                                },
                                [
                                  _vm.videoInputs == null ||
                                  _vm.videoInputs.length !== 0
                                    ? _c(
                                        "span",
                                        { staticClass: "media-text" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.selectedVideo.label)
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.videoInputs == null ||
                                  _vm.videoInputs.length !== 0
                                    ? _c("b-icon", {
                                        staticClass: "drop-ico",
                                        attrs: {
                                          icon: active
                                            ? "chevron-up"
                                            : "chevron-down"
                                        }
                                      })
                                    : _c("span", [
                                        _vm._v("No video devices found")
                                      ])
                                ],
                                1
                              )
                            }
                          }
                        ],
                        null,
                        false,
                        3649910916
                      )
                    },
                    _vm._l(_vm.videoInputs, function(input) {
                      return _c(
                        "b-dropdown-item",
                        {
                          key: input.deviceId,
                          staticClass: "hidden",
                          attrs: {
                            "aria-role": "listitem",
                            value: input.label
                          },
                          on: {
                            click: function($event) {
                              return _vm.onVideoDeviceChanged(input)
                            }
                          }
                        },
                        [_vm._v(_vm._s(input.label))]
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _c("p", [_vm._v("Audio")]),
              _vm.audioInputs !== null
                ? _c(
                    "b-dropdown",
                    {
                      attrs: {
                        value: _vm.selectedAudio.label,
                        "aria-role": "list",
                        expanded: ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "trigger",
                            fn: function(ref) {
                              var active = ref.active
                              return _c(
                                "b-button",
                                {
                                  staticClass: "media-input mic",
                                  attrs: {
                                    "icon-left": "microphone-alt",
                                    expanded: ""
                                  }
                                },
                                [
                                  _vm.audioInputs == null ||
                                  _vm.audioInputs.length !== 0
                                    ? _c(
                                        "span",
                                        { staticClass: "media-text" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.selectedAudio.label)
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.audioInputs == null ||
                                  _vm.audioInputs.length !== 0
                                    ? _c("b-icon", {
                                        staticClass: "drop-ico",
                                        attrs: {
                                          icon: active
                                            ? "chevron-up"
                                            : "chevron-down"
                                        }
                                      })
                                    : _c("span", [
                                        _vm._v("No audio devices found")
                                      ])
                                ],
                                1
                              )
                            }
                          }
                        ],
                        null,
                        false,
                        3498146304
                      )
                    },
                    _vm._l(_vm.audioInputs, function(input) {
                      return _c(
                        "b-dropdown-item",
                        {
                          key: input.deviceId,
                          staticClass: "hidden",
                          attrs: {
                            "aria-role": "listitem",
                            value: input.label
                          },
                          on: {
                            click: function($event) {
                              return _vm.onAudioDeviceChanged(input)
                            }
                          }
                        },
                        [_vm._v(_vm._s(input.label))]
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _c("hr"),
              _c(
                "div",
                { staticClass: "buttons" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { type: "is-success" },
                      on: {
                        click: function($event) {
                          return _vm.$emit(
                            "join",
                            _vm.selectedAudio,
                            _vm.selectedVideo
                          )
                        }
                      }
                    },
                    [_vm._v("Join")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { type: "is-danger" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("cancel")
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "column is-6" }, [
            _c(
              "div",
              { attrs: { id: "local-media" } },
              [
                _c("volume-indicator", {
                  staticClass: "volume",
                  attrs: { volume: _vm.audioLevel, "max-volume": 10 }
                })
              ],
              1
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }