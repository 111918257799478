import { __decorate, __extends } from "tslib";
import store from '@/store';
import { Vue, Component, Watch } from 'vue-property-decorator';
import StyleObjects from './StyleObjects.vue';
var Autodesk = window.Autodesk;
var type = null;
var Markup = (function (_super) {
    __extends(Markup, _super);
    function Markup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.showToolBar = false;
        _this.Color = { Line: '#FF0000', Fill: '#FF0000', Width: 7, Opacity: '50%' };
        _this.markupExtension = null;
        _this.Markup = null;
        _this.Viewer = null;
        return _this;
    }
    Markup.prototype.getMarkButton = function () {
        this.toolBarBtn = document.getElementById('markupBtn');
        this.addEvents();
    };
    Markup.prototype.emitColor = function (childrenColor) {
        this.Color = childrenColor;
        this.setColorMarkup();
    };
    Markup.prototype.addEvents = function () {
        this.toolBarBtn.addEventListener('click', this.defaultIconToolBar);
        this.markupExtension = window.NOP_VIEWER.getExtension('Autodesk.Viewing.MarkupsCore');
        this.Markup = Autodesk.Viewing.Extensions.Markups.Core;
        this.markupExtension.addEventListener(this.Markup.EVENT_MARKUP_SELECTED, function () {
            this.setColorMarkup();
        }.bind(this));
        this.markupExtension.addEventListener(this.Markup.EVENT_EDITMODE_CHANGED, function () {
            this.setColorMarkup();
        }.bind(this));
    };
    Markup.prototype.setColorMarkup = function () {
        var styleAttributes = ['stroke-width', 'stroke-color', 'fill-color', 'fill-opacity'];
        var nsu = Autodesk.Viewing.Extensions.Markups.Core.Utils;
        var styleObject = nsu.createStyle(styleAttributes, this.markupExtension);
        styleObject['stroke-width'] = this.Color.Width;
        styleObject['stroke-color'] = this.Color.Line;
        styleObject['fill-color'] = this.Color.Fill;
        styleObject['fill-opacity'] = this.Color.Opacity;
        this.markupExtension.setStyle(styleObject);
    };
    Markup.prototype.saveMarkup = function () {
    };
    Markup.prototype.selectedIcon = function (_a) {
        var target = _a.target;
        this.setSelectedIcon(target);
    };
    Markup.prototype.setSelectedIcon = function (target) {
        var type = target.getAttribute('type');
        this.setClickedButtonActive(target);
        this.setSelectedMarkup(type);
        this.hideAllSubMenuItems();
    };
    Markup.prototype.selectedSubMenuIcon = function (_a) {
        var target = _a.target;
        var type = target.getAttribute('type');
        var idParent = target.getAttribute('parent');
        var parentTarget = document.getElementById(idParent);
        parentTarget.setAttribute('type', type);
        parentTarget.querySelector('img').src = target.querySelector('img').src;
        this.setClickedButtonActive(parentTarget);
        this.setSelectedMarkup(type);
        this.showHideSubMenuItem(idParent);
    };
    Markup.prototype.setClickedButtonActive = function (target) {
        var markupButtons = document.querySelector('#Markup').querySelectorAll('.btn-group');
        var activeClass = 'btn-group--selected-yes';
        markupButtons.forEach(function (_a) {
            var classList = _a.classList;
            return classList.remove(activeClass);
        });
        target.classList.add(activeClass);
    };
    Markup.prototype.defaultIconToolBar = function () {
        this.setSelectedMarkup(this.Markup.MARKUP_TYPE_FREEHAND);
    };
    Markup.prototype.setSelectedMarkup = function (name) {
        this.showToolBar = true;
        this.markupExtension.enterEditMode();
        var newMarkup = this.getMarkupInstance(name);
        this.markupExtension.changeEditMode(newMarkup);
    };
    Markup.prototype.getMarkupInstance = function (name) {
        var Markup = this.Markup;
        var newMarkup = new Markup.EditModeFreehand(this.markupExtension);
        if (name == this.Markup.MARKUP_TYPE_FREEHAND)
            return newMarkup;
        switch (name) {
            case this.Markup.MARKUP_TYPE_HIGHLIGHT:
                newMarkup = new Markup.EditModeHighlight(this.markupExtension);
                break;
            case this.Markup.MARKUP_TYPE_TEXT:
                newMarkup = new Markup.EditModeText(this.markupExtension);
                break;
            case this.Markup.MARKUP_TYPE_CALLOUT:
                newMarkup = new Markup.EditModeCallout(this.markupExtension);
                break;
            case this.Markup.MARKUP_TYPE_CLOUD:
                newMarkup = new Markup.EditModeCloud(this.markupExtension);
                break;
            case this.Markup.MARKUP_TYPE_POLYCLOUD:
                newMarkup = new Markup.EditModePolycloud(this.markupExtension);
                break;
            case this.Markup.MARKUP_TYPE_ARROW:
                newMarkup = new Markup.EditModeArrow(this.markupExtension);
                break;
            case this.Markup.MARKUP_TYPE_POLYLINE:
                newMarkup = new Markup.EditModePolyline(this.markupExtension);
                break;
            case this.Markup.MARKUP_TYPE_CIRCLE:
                newMarkup = new Markup.EditModeCircle(this.markupExtension);
                break;
            case this.Markup.MARKUP_TYPE_RECTANGLE:
                newMarkup = new Markup.EditModeRectangle(this.markupExtension);
                break;
            case this.Markup.MARKUP_TYPE_DIMENSION:
                newMarkup = new Markup.EditModeDimension(this.markupExtension);
                break;
        }
        return newMarkup;
    };
    Object.defineProperty(Markup.prototype, "isToolBarLoaded", {
        get: function () {
            return store.state.viewer.isToolBarCreated;
        },
        enumerable: false,
        configurable: true
    });
    Markup.prototype.hideMarkup = function () {
        console.log('dentro');
        this.markupExtension.leaveEditMode();
        this.showToolBar = false;
        this.markupExtension.hide();
    };
    Markup.prototype.subMenuItems = function (_a) {
        var target = _a.target;
        var parent = target.parentNode;
        this.showHideSubMenuItem(parent.getAttribute('parent'));
    };
    Markup.prototype.showHideSubMenuItem = function (type) {
        var element = document.getElementsByClassName(type).item(0);
        var hiddenClass = 'hidden';
        if (element.classList.contains(hiddenClass)) {
            element.classList.remove(hiddenClass);
            var li = document.querySelector('#Markup').querySelectorAll(".sub-items:not(." + type + ")");
            li.forEach(function (_a) {
                var classList = _a.classList;
                return classList.add(hiddenClass);
            });
        }
        else {
            element.classList.add(hiddenClass);
        }
    };
    Markup.prototype.hideAllSubMenuItems = function () {
        var li = document.querySelector('#Markup').querySelectorAll('.sub-items');
        var hiddenClass = 'hidden';
        li.forEach(function (_a) {
            var classList = _a.classList;
            return classList.add(hiddenClass);
        });
    };
    __decorate([
        Watch('isToolBarLoaded')
    ], Markup.prototype, "getMarkButton", null);
    Markup = __decorate([
        Component({
            components: { StyleObjects: StyleObjects }
        })
    ], Markup);
    return Markup;
}(Vue));
export default Markup;
