var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-pointer", style: _vm.style, attrs: { id: _vm.id } },
    [
      _c("b-icon", { staticClass: "pointer", attrs: { icon: "chevron-left" } }),
      _c("b-tag", { staticClass: "name" }, [_vm._v(_vm._s(_vm.userId))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }