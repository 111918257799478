<template>
  <Hero color="clear login-container">
    <p class="title has-text-weight-normal is-4">
      You must be logged in in order to access meeting
      <span class="is-italic has-text-primary has-text-weight-semibold">login {{ id }}</span>
    </p>
    <Login />
  </Hero>
</template>

<script>
import Login from '@/components/auth/Login'
export default {
  components: { Login },
  props: {
    id: String
  }
}
</script>

<style lang="scss" scoped>
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
