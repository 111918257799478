var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("svg", { attrs: { height: "40", width: "120" } }, [
    _c("circle", {
      attrs: { cx: "10", cy: "20", r: _vm.radius / 2, fill: "white" }
    }),
    _c("circle", {
      attrs: { cx: "25", cy: "20", r: _vm.radius, fill: "white" }
    }),
    _c("circle", {
      attrs: { cx: "40", cy: "20", r: _vm.radius / 2, fill: "white" }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }