import { render, staticRenderFns } from "./BottomRightMenu.vue?vue&type=template&id=6497c7dc&scoped=true&lang=pug&"
import script from "./BottomRightMenu.vue?vue&type=script&lang=js&"
export * from "./BottomRightMenu.vue?vue&type=script&lang=js&"
import style0 from "./BottomRightMenu.vue?vue&type=style&index=0&id=6497c7dc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6497c7dc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Apogea\\Projects\\bim-meet\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6497c7dc')) {
      api.createRecord('6497c7dc', component.options)
    } else {
      api.reload('6497c7dc', component.options)
    }
    module.hot.accept("./BottomRightMenu.vue?vue&type=template&id=6497c7dc&scoped=true&lang=pug&", function () {
      api.rerender('6497c7dc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/meeting/BottomRightMenu.vue"
export default component.exports