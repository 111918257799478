import { __decorate, __extends } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Sentence from '@/components/twilio/ChatWidget/Sentence.vue';
import { VEmojiPicker } from 'v-emoji-picker';
import { directive as onClickaway } from 'vue-clickaway';
var ChatWidget = (function (_super) {
    __extends(ChatWidget, _super);
    function ChatWidget() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.sentences = [];
        _this.message = '';
        _this.showEmojis = false;
        return _this;
    }
    ChatWidget.prototype.mounted = function () {
        var _this = this;
        var observer = this.$apollo.subscribe({
            query: require('@/graphql/GetMessages.gql'),
            variables: { id: this.$route.params.id }
        });
        observer.subscribe({
            next: function (_a) {
                var data = _a.data;
                _this.$log('warning', 'data send', data);
                _this.sentences.push(data.chatMessage);
            },
            error: function (error) {
                _this.$log('warning', 'error', error);
            }
        });
    };
    ChatWidget.prototype.hideModal = function () {
        if (this.showEmojis === true)
            this.showEmojis = false;
    };
    ChatWidget.prototype.sendMessage = function () {
        var _this = this;
        if (!this.message)
            return;
        var variables = {
            id: this.$route.params.id,
            name: this.$auth.currentUser.email,
            message: this.message
        };
        this.$log('warn', '-> sendMessage -> variables', variables);
        this.$apollo
            .mutate({
            mutation: require('@/graphql/addChatMessage.gql'),
            variables: variables
        })
            .catch(function (err) { return console.log(err.graphQLErrors[0].message); })
            .finally(function () { return _this.resetInput(); });
    };
    ChatWidget.prototype.resetInput = function () {
        this.message = '';
        var input = document.getElementById('chat-input');
        input.focus();
    };
    ChatWidget.prototype.selectEmoji = function (emoji) {
        this.message += emoji.data;
    };
    Object.defineProperty(ChatWidget.prototype, "emojiColourWhenOpened", {
        get: function () {
            return this.showEmojis && 'filter: grayscale(0%);';
        },
        enumerable: false,
        configurable: true
    });
    ChatWidget.prototype.onClickedChanged = function () {
        this.clicked && this.resetInput();
    };
    __decorate([
        Prop({ default: false })
    ], ChatWidget.prototype, "clicked", void 0);
    __decorate([
        Watch('clicked')
    ], ChatWidget.prototype, "onClickedChanged", null);
    ChatWidget = __decorate([
        Component({
            components: { Sentence: Sentence, VEmojiPicker: VEmojiPicker },
            directives: { onClickaway: onClickaway }
        })
    ], ChatWidget);
    return ChatWidget;
}(Vue));
export default ChatWidget;
